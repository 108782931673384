import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap';

import winnerChestGif from '../images/gifs/winnerChest_noloop.gif'



export default function CherryspinsModalWin({ show, winnerChestJPG }) {

	// useEffect(() => {
	//   show && document.orozino.fireworks('init');
	// });

	return (
		<Modal show={show} onHide={() => null} centered>
			{/* <div className="pyro fadeOut">
          <div className="before"></div>
          <div className="after"></div>
      </div> */}
			<Modal.Body className="fade-in" style={{ textAlign: "center", padding: "1.75rem" }}>
				{/* <SignUpForm /> */}
				<div style={{ width: "100%", position: "relative" }}>
					<img src={winnerChestJPG} alt="treasure chest" style={{ width: "100%" }} />
					<img src={winnerChestGif} alt="treasure chest" style={{ width: "100%", position: "absolute", top: 0, left: 0 }} />
				</div>
				{/* <Img
            title="Logo"
            alt="Juegos de fortuna"
            fluid={data.whiteLogo.edges[0].node.childImageSharp.fluid}
            style={{maxWidth: "200px", margin: "auto", marginBottom: "20px"}}
        /> */}
				<h4 style={{ fontWeight: "bold", marginBottom: "15px" }}>Congratulations!</h4>

				{/* <h5 style={{ marginBottom: "20px" }}>
					Obtén <span style={{ fontWeight: "bold" }}>150% del bono</span> de hasta <span style={{ fontWeight: "bold" }}>S/800</span>
				</h5> */}

				<h5 style={{ marginBottom: "20px" }}>
					You have unlocked a bonus of <b>1,000&nbsp;free&nbsp;spins</b>!
				</h5>

				<a href="https://trancesscomended.com/click" className={`btn btn-primary main-cta cta-large gtm-main-cta`}>Claim now</a>

				{/* <p style={{ marginTop: "20px" }}>
					<small>Complete </small>
				</p> */}
			</Modal.Body>
		</Modal>
	);
}