import React, { useState, useEffect } from 'react'
import { Modal } from 'react-bootstrap';

import winnerChestGif from '../images/olympus/win-animation.gif'



export default function OlympusSlotsModalWin({ show, winnerChestJPG }) {

	// useEffect(() => {
	//   show && document.orozino.fireworks('init');
	// });

	return (
		<Modal show={show} onHide={() => null} centered>
			{/* <div className="pyro fadeOut">
          <div className="before"></div>
          <div className="after"></div>
      </div> */}
			<Modal.Body className="fade-in" style={{ textAlign: "center", padding: "1.75rem" }}>
				{/* <SignUpForm /> */}
				<div style={{ width: "100%", position: "relative" }}>
					{/* <img src={winnerChestJPG} alt="treasure chest" style={{ width: "100%" }} /> */}
					<img src={winnerChestGif} alt="treasure chest" style={{ height: "300px", top: 0, left: 0, marginTop: "-40px"}} />
				</div>
				{/* <Img
            title="Logo"
            alt="Juegos de fortuna"
            fluid={data.whiteLogo.edges[0].node.childImageSharp.fluid}
            style={{maxWidth: "200px", margin: "auto", marginBottom: "20px"}}
        /> */}
				<h4 style={{ fontWeight: "bold" }}>Congratulations!</h4>

				{/* <h5 style={{ marginBottom: "20px" }}>
					Obtén <span style={{ fontWeight: "bold" }}>150% del bono</span> de hasta <span style={{ fontWeight: "bold" }}>S/800</span>
				</h5> */}

				<h5 style={{ marginBottom: "20px", lineHeight: "150%" }}>
					You won! <b>250 free spins</b> bonus in Gates&nbsp;of&nbsp;Olympus.
				</h5>

				<a href={`https://cherry-land-bash.online/?_lp=1&${window.location.search.substring(1)}`} className={`btn btn-primary main-cta cta-large gtm-main-cta`}>Claim your prize</a>

			</Modal.Body>
		</Modal>
	);
}