import React, { useState, useEffect } from "react"

//Components
import CherryspinsHeader from "../components/CherryspinsHeader";
import CherryspinsSlotsGame from "../components/CherryspinsSlotsGame";
import CherryspinsLoader from "../components/CherryspinsLoader";

import './cherryspinsSlots.scss';

export default function CherryspinsSlots() {

    useEffect(() => {
        // console.log(imgDesktopBackground)
    });

    return (
        <section className="cherry-spins-slots" >
            <div className="page-wrapper">
                <CherryspinsHeader />
                <CherryspinsSlotsGame bannerImageType="standard"/>
                <CherryspinsLoader />
            </div>
        </section>
    )
}