import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';

import OrozinoSlots from './pages/OrozinoSlots';
import OlympusSlots from "./pages/OlympusSlots";
import BigBassSlots from "./pages/BigBassSlots";
import CherryspinsSlots from "./pages/CherryspinsSlots";
import CherryspinsFacebook from "./pages/CherryspinsFacebook";
import CherryspinsFacebookRetarget from "./pages/CherryspinsFacebookRetarget";
import CherryspinsGeneralTraffic from "./pages/CherryspinsGeneralTraffic";

function App() {
	return (
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<p>404</p>} />
				<Route path="slots" element={ <CherryspinsSlots /> } />
				<Route path="orozino-slots" element={ <OrozinoSlots /> } />
				<Route path="olympus-slots" element={ <OlympusSlots /> } />
				<Route path="big-bass-slots" element={ <BigBassSlots /> } />
				<Route path="facebook-promotion" element={ <CherryspinsFacebook /> } />
				<Route path="facebook-100-promotion" element={ <CherryspinsFacebookRetarget /> } />
				<Route path="10-spins-promotion" element={ <CherryspinsGeneralTraffic /> } />
			</Routes>
		</BrowserRouter>
	);
}

export default App;
