import React, { useEffect } from "react"

import logo from '../images/cherry-spins/logo.png'

import './cherryspinsHeader.scss';

export default function CherryspinsHeaderAslan() {

    useEffect(() => { }, [])

    return (
        <section className="cherry-spins-nav">
            <nav className="nav">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-6 align-self-center ">
                            <a href={`https://cherry-land-bash.online/?_lp=1&${window.location.search.substring(1)}`}>
                                <img src={logo} className="logo" alt="Cherry Spins" />
                            </a>
                        </div>
                        <div className="col-6 align-self-center text-right">
                            <a href={`https://cherry-land-bash.online/?_lp=1&${window.location.search.substring(1)}`} className="btn btn-register">Register</a>
                        </div>
                    </div>
                </div>
            </nav>
        </section>
    )
}