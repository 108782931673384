import React, { useState, useEffect } from "react"
import styles from './Header.module.css'

import imgLogo from '../images/orozino/logo.png'

export default function OroHeader () {

  useEffect(() => {}, [])

  return <>
  <nav className={`${styles.nav} ${styles.headerNav}`}>
        <div className="container-fluid">
            <div className="row">
                <div className="col-6 align-self-center ">
                    <img src={imgLogo} style={{maxWidth:'200px'}} alt="Juegos de fortuna"/>
                </div>
            </div>
        </div>
  </nav>
  </>
}