import React, { useState, useEffect } from "react"

import styles from '../page_css/OlympusSlots.module.css';

//Components
import CherryspinsHeaderAslan from "../components/CherryspinsHeaderAslan"
import OlympusSlotsComponent from "../components/OlympusSlotsComponent"
import OroLoader from "../components/OroLoader"

export default function OlympusSlots() {

    useEffect(() => {
        // console.log(imgDesktopBackground)
    });

    return (
        <section className={styles.pageWrapper} >
            <CherryspinsHeaderAslan />
            <OlympusSlotsComponent bannerImageType="standard"/>
            <OroLoader />
        </section>
    )
}