import React, { useState, useEffect } from "react";

import './cherryspinsLoader.scss';

export default function OroLoader() {

    const [isLoaderActive, setLoaderActive] = useState(true);
    const [isLoaderVisible, setLoaderVisible] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoaderVisible(false);
        }, 1000)

        setTimeout(() => {
            setLoaderActive(false);
        }, 1550)
    });

    return (
        <div className="cherry-spins-loader">
            <div className={`loading-container ${isLoaderVisible ? 'opacity-1' : 'loader-fade-out'} ${isLoaderActive ? "d-flex" : "d-none"}`} >
                <div className='loading'>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    );
}