import React, { useEffect } from "react"

import logo from '../images/cherry-spins/logo-facebook.png'

import './cherryspinsHeader.scss';

export default function CherryspinsHeader() {

    useEffect(() => { }, [])
    
    const home = () => {
        try {
            window.FbEvents.Contact().then(data => {
                console.log(data)
                window.location.href = 'https://cherryspins.com?tsource=facebook';
            });
        } catch (error) {
            console.log(error)
            window.location.href = 'https://cherryspins.com?tsource=facebook'
        }
    }

    const register = () => {
        try {
            window.FbEvents.Contact().then(data => {
                console.log(data)
                window.location.href = 'https://cherryspins.com/ca-en/?register&tsource=facebook'
            });
        } catch (error) {
            console.log(error)
            window.location.href = 'https://cherryspins.com/ca-en/?register&tsource=facebook'
        }
    }

    return (
        <section className="cherry-spins-nav">
            <nav className="nav">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-6 align-self-center ">
                            {/* <a href="#" onClick={home}>
                                <img src={logo} className="logo" alt="Cherry Spins" />
                            </a> */}
                            <button className="btn-logo" onClick={home}>
                                <img src={logo} className="logo" alt="Cherry Spins" />
                            </button>
                        </div>
                        <div className="col-6 align-self-center text-right">
                            {/* <a href="https://cherryspins.com/ca-en/?register" className="btn btn-register">Register</a> */}
                            <button className="btn btn-register" onClick={register}>Register</button>
                        </div>
                    </div>
                </div>
            </nav>
        </section>
    )
}