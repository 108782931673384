import React, { useState, useEffect, useRef } from "react"

// Components
import BigBassSlotsModalWin from "./BigBassSlotsModalWin"
import Div100vh from 'react-div-100vh'
import img from '../images/bigbass/front-image.png';

// Styles
import styles from './OlympusSlotsComponent.module.css'
import './bigBassBanner.scss';
import light from '../images/cherry-spins/light.png';

// Images
import slotIcon1 from '../images/slot-icons/1.png';
import slotIcon2 from '../images/slot-icons/2.png';
import slotIcon3 from '../images/slot-icons/3.png';
import slotIcon4 from '../images/slot-icons/4.png';
import slotIcon5 from '../images/bigbass/5.png';

import slotBackground from '../images/bigbass/slots-background.png';
import slotMask from '../images/bigbass/slot-mask.png';
import slotHandle from '../images/bigbass/slot-handle.png';

export default function BigBassSlotsComponent({ bannerImageType }) {

    // Icon array
    const slotIcons = [slotIcon1, slotIcon2, slotIcon3, slotIcon4, slotIcon5]
    const arReelIcons = [...slotIcons, ...slotIcons];

    // states
    const [isRolling, setIsRolling] = useState(false);
    const [round, setRound] = useState(0);
    const [show, setShow] = useState(false);
    const [screenWidth, setScreenWidth] = useState(0);

    // Slot settings - MOBILE
    const [imageHeight, setImageHeight] = useState(0);
    const [cutOffPoint, setCutOffPoint] = useState(0);
    const [lastElementTop, setLastElementTop] = useState(0);

    // References to reel containers
    const refReelOne = useRef(null);
    const refReelTwo = useRef(null);
    const refReelThree = useRef(null);

    // Completed rotations
    let rotationsCompleteReel1 = 0;
    let rotationsCompleteReel2 = 0;
    let rotationsCompleteReel3 = 0;

    // Rotation speed
    let speed = 30;

    // Reel rotations per round
    let arReqRotations = [
        {
            reel1: 9,
            reel2: 16,
            reel3: 24
        },
        {
            reel1: 9,
            reel2: 15,
            reel3: 24
        },
        {
            reel1: 8,
            reel2: 16,
            reel3: 25
        }

    ];

    useEffect(() => {
        //let screenWidth = window.screen.width;
        setScreenWidth(window.innerWidth);

        if (screenWidth >= 1200) {
            // DESKTOP HD
            setCutOffPoint(225);
            setImageHeight(160);
            setLastElementTop(1215);
        }
        else if (screenWidth > 999 && screenWidth < 1200) {
            // DESKTOP
            setCutOffPoint(173);
            setImageHeight(120);
            setLastElementTop(907);
        }
        else {
            // MOBILE
            setImageHeight(70);
            setCutOffPoint(102);
            setLastElementTop(528);
        }

        window.addEventListener('resize', screenWidthChangeListener);

        return () => {
            window.removeEventListener('resize', screenWidthChangeListener);
        }

        //console.log(round);
        // if(round === 0) rotateSlots();

        // if (document.cookie.split(';').find(el => el.startsWith(' spins'))) {
        //     setShow(true);
        // }

    }, [screenWidth, cutOffPoint, imageHeight, lastElementTop]);

    const screenWidthChangeListener = () => {
        setScreenWidth(window.innerWidth);
    }

    const btnClickRoll = () => {
        setIsRolling(true);
        rotateSlots();
    }

    const rotateSlots = () => {
        let curArReelOne = Array.from(refReelOne.current.children);
        let curArReelTwo = Array.from(refReelTwo.current.children);
        let curArReelThree = Array.from(refReelThree.current.children);

        let reqRotations = round < 3 ? arReqRotations[round] : arReqRotations[0];

        requestAnimationFrame(function spinSlots(timestamp) {

            curArReelOne.forEach(el => {
                if (rotationsCompleteReel1 < reqRotations.reel1) {

                    let elTop = el.offsetTop;

                    if (elTop < cutOffPoint) {
                        // we move element until it reaches the cutOff point
                        el.style.top = `${elTop + speed}px`;
                    }
                    else {
                        // the element reached the cut of point
                        // check if this is not the last rotation
                        if (rotationsCompleteReel1 + 1 < reqRotations.reel1) {

                            // calculate real slot position after animation
                            let offset = elTop - cutOffPoint;

                            // update the div 
                            refReelOne.current.removeChild(el);
                            refReelOne.current.appendChild(el);

                            curArReelOne = Array.from(refReelOne.current.children);
                            el.style.top = (-lastElementTop - imageHeight + offset + speed) + 'px';
                        }

                        rotationsCompleteReel1 += 1;
                    }
                }
            });

            curArReelTwo.forEach(el => {
                if (rotationsCompleteReel2 < reqRotations.reel2) {

                    let elTop = el.offsetTop;

                    if (elTop < cutOffPoint) {
                        // we move element until it reaches the cutOff point
                        el.style.top = `${elTop + speed}px`;
                    }
                    else {
                        // the element reached the cut of point
                        // check if this is not the last rotation
                        if (rotationsCompleteReel2 + 1 < reqRotations.reel2) {

                            // calculate real slot position after animation
                            let offset = elTop - cutOffPoint;

                            // update the div 
                            refReelTwo.current.removeChild(el);
                            refReelTwo.current.appendChild(el);

                            curArReelTwo = Array.from(refReelTwo.current.children);
                            el.style.top = (-lastElementTop - imageHeight + offset + speed) + 'px';
                        }

                        rotationsCompleteReel2 += 1;
                    }
                }
            });

            curArReelThree.forEach(el => {
                if (rotationsCompleteReel3 < reqRotations.reel3) {

                    let elTop = el.offsetTop;

                    if (elTop < cutOffPoint) {
                        // we move element until it reaches the cutOff point
                        el.style.top = `${elTop + speed}px`;
                    }
                    else {
                        // the element reached the cut of point
                        // check if this is not the last rotation
                        if (rotationsCompleteReel3 + 1 < reqRotations.reel3) {

                            // calculate real slot position after animation
                            let offset = elTop - cutOffPoint;

                            // update the div 
                            refReelThree.current.removeChild(el);
                            refReelThree.current.appendChild(el);

                            curArReelThree = Array.from(refReelThree.current.children);
                            el.style.top = (-lastElementTop - imageHeight + offset + speed) + 'px';
                        }

                        rotationsCompleteReel3 += 1;
                    }
                }
            });

            if (rotationsCompleteReel1 === reqRotations.reel1) {
                let offset = curArReelOne[0].offsetTop - cutOffPoint;

                curArReelOne.forEach((el, i) => {
                    el.style.top = `${el.offsetTop - offset}px`;
                });
            }

            if (rotationsCompleteReel2 === reqRotations.reel2) {
                let offset = curArReelTwo[0].offsetTop - cutOffPoint;

                curArReelTwo.forEach((el, i) => {
                    el.style.top = `${el.offsetTop - offset}px`;
                });
            }

            if (rotationsCompleteReel3 === reqRotations.reel3) {
                let offset = curArReelThree[0].offsetTop - cutOffPoint;

                curArReelThree.forEach((el, i) => {
                    el.style.top = `${el.offsetTop - offset}px`;
                });
            }

            if (rotationsCompleteReel1 === reqRotations.reel1 &&
                rotationsCompleteReel2 === reqRotations.reel2 &&
                rotationsCompleteReel3 === reqRotations.reel3) {

                setIsRolling(false);
                setRound(round + 1);

                round === 2 && setTimeout(() => {
                    setShow(true);
                }, 400);
            }
            else {
                //request next animation frame
                requestAnimationFrame(spinSlots);
            }

        });
    }


    return <>
        <Div100vh className={styles.slotsSection} style={{position: 'relative',overflow: "hidden"}}>
            
            <div className="bigbass-banner">
                <img className='banner-image' src={img} alt=""/>
            </div>

            <div className="container-fluid h-100" style={{position: 'relative',zIndex: '2'}}>
                <div className="row h-100 align-items-end align-items-md-center">
                    <div className="col-12 col-lg-7 offset-lg-5 col-xl-7 offset-xl-5">

                        <img src={light} className="light-background" />

                        <h1>You got <span className={styles.price}>3 free spins</span>.
                            <br />
                            Try your luck now!
                        </h1>

                        <div className={styles.slotMachine}>
                            <div className={styles.reelsContainer} style={{ backgroundImage: `url(${slotBackground})` }}>
                                <div className={styles.slotMask} style={{ backgroundImage: `url(${slotMask})` }}></div>
                                <div className={styles.reel} ref={refReelOne}>
                                    {
                                        arReelIcons.map((el, i) => {
                                            return <div className={styles.slotIcon} key={i} style={{ top: `${(i * -imageHeight) + cutOffPoint}px` }}>
                                                {/* <Img className={`img-fluid`}
                                            fluid={el.node.children[0].fluid}
                                            loading={"eager"} /> */}
                                                <img className="img img-fluid" src={el} width='100%' />
                                            </div>
                                        })
                                    }
                                </div>
                                <div className={styles.reel} ref={refReelTwo}>
                                    {arReelIcons.splice(0, 0, arReelIcons.pop())}
                                    {
                                        arReelIcons.map((el, i) => {
                                            return <div className={styles.slotIcon} key={i} style={{ top: `${(i * -imageHeight) + cutOffPoint}px` }}>
                                                {/* <Img className={`img-fluid`}
                                            fluid={el.node.children[0].fluid} 
                                            loading={"eager"}/> */}
                                                <img className="img img-fluid" src={el} width='100%' />
                                            </div>
                                        })
                                    }
                                </div>
                                <div className={styles.reel} ref={refReelThree}>
                                    {arReelIcons.splice(0, 0, arReelIcons.pop())}
                                    {
                                        arReelIcons.map((el, i) => {
                                            return <div className={styles.slotIcon} key={i} style={{ top: `${(i * -imageHeight) + cutOffPoint}px` }}>
                                                <img className="img img-fluid" src={el} width='100%' />
                                            </div>
                                        })
                                    }
                                </div>
                                <div className={styles.handle} style={{ backgroundImage: `url(${slotHandle})` }}></div>
                            </div>
                        </div>

                        <div className={styles.btnRollContainer}>
                            <button className={`btn btn-primary main-cta main-cta-olympus cta-large ${styles.btnRoll} ${!isRolling ? styles.rolling : null} gtm-btn-hero-signup`}
                                onClick={() => !isRolling && btnClickRoll()}
                                disabled={isRolling}
                            >
                                {isRolling ? "PLAY NOW" : "PLAY NOW"}
                            </button>
                        </div>

                        {/* <a href="https://vantalphabeller.com/click" className={`btn btn-primary main-cta cta-large gtm-btn-hero-signup`}>Continuar</a> */}
                    </div>
                </div>

            </div>

        </Div100vh>
        <BigBassSlotsModalWin show={show} />
    </>

}