import React, { useEffect } from "react"

import logo from '../images/cherry-spins/logo.png'

import './cherryspinsHeader.scss';

export default function CherryspinsHeader() {

    useEffect(() => { }, [])

    return (
        <section className="cherry-spins-nav">
            <nav className="nav">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-6 align-self-center ">
                            <a href="https://cherryspins.com">
                                <img src={logo} className="logo" alt="Cherry Spins" />
                            </a>
                        </div>
                        <div className="col-6 align-self-center text-right">
                            <a href="https://cherryspins.com/ca-en/?register" className="btn btn-register">Register</a>
                        </div>
                    </div>
                </div>
            </nav>
        </section>
    )
}