import React, { useState, useEffect } from "react"
import styles from './OroLoader.module.css'

export default function OroLoader () {

    const [isLoaderActive, setLoaderActive] = useState(true);
    const [isLoaderVisible, setLoaderVisible] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoaderVisible(false);
        }, 3000)

        setTimeout(() => {
            setLoaderActive(false);
        }, 3550)
    });

  return (
  <div className={`${styles.container} ${isLoaderVisible ? styles.opacity1 : styles.loaderFadeOut} ${isLoaderActive ? "d-flex" : "d-none"}`} >
      <div className={styles.loading}>
        <div className={styles.line}></div>
        <div className={styles.line}></div>
        <div className={styles.line}></div>
    </div>
  </div>
  );
}