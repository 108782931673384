import React, { useState, useEffect } from "react"

import styles from '../page_css/OrozinoSlots.module.css';

//Components
import OroHeader from "../components/OroHeader"
import OroSlots from "../components/OroSlots"
import OroLoader from "../components/OroLoader"

export default function OrozinoSlots() {

    useEffect(() => {
        // console.log(imgDesktopBackground)
    });

    return (
        <section className={styles.pageWrapper} >
            <OroHeader />
            <OroSlots bannerImageType="standard"/>
            <OroLoader />
        </section>
    )
}