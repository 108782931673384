import React, { useState, useEffect } from "react"
import styles from './OroBannerImage.module.css'

import imgStandard from '../images/orozino/orozino-woman.png';
import imgAdult from '../images/orozino/orozino-woman.png';
import imgPool from '../images/orozino/orozino-woman.png';

export default function OroBannerImage ({ type }) {
    
    const images = {
        standard: {
            src: imgStandard,
            className: styles.bannerImage
        },
        adult: {
            src: imgAdult,
            className: styles.bannerImageAdult
        },
        pool: {
            src: imgPool,
            className: styles.bannerImagePool
        }
    }

    return <img className={images[type].className} src={images[type].src} style={{zIndex: '1'}}/>;
}