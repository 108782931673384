import React from "react"

import img from '../images/cherry-spins/slots-front.png';

import './cherryspinsBanner.scss';

export default function OroBannerImage() {
    return (
        <div className="cherry-spins-banner">
            <img className='banner-image' src={img} alt=""/>
        </div>
    );
}