import React, { useState, useEffect } from "react"

//Components
import CherryspinsGeneralHeader from "../components/CherryspinsGeneralHeader";
import CherryspinsLoader from "../components/CherryspinsLoader";

import light from '../images/cherry-spins/light-color.png';
import board from '../images/cherry-spins/board.png';
import img from '../images/cherry-spins/main-banner.png';

import styles from '../components/OroSlots.module.css';
import '../components/cherryspinsSlotsGame.scss';
import './cherryspinsFacebook.scss';

export default function CherryspinsGeneralTraffic() {

    useEffect(() => { });

    const playNow = () => {
        try {
            window.FbEvents.Contact().then(data => {
                console.log(data)
                window.location.href = 'https://cherryspins.com/ca-en/?register&tsource=google'
            });
        } catch (error) {
            console.log(error)
            window.location.href = 'https://cherryspins.com/ca-en/?register&tsource=google'
        }
    }

    return (
        <section className="cherry-spins-facebook" >
            <div className="page-wrapper">

                <CherryspinsGeneralHeader />

                <section className={styles.slotsSection}>
                    <div className={styles.slotWrapper}>

                        {/* <div className="cherry-spins-facebook-banner">
                            <img className='facebook-banner-image' src={img} alt="" />
                        </div> */}

                        <div className="container-fluid h-100" style={{ position: 'relative', zIndex: '2' }}>
                            <div className="row h-100 align-items-center">
                                <div className="col-12 col-lg-6 offset-lg-3 col-xl-6 offset-xl-3 facebook-offer-container">
                                    <img src={light} className="light-background" />

                                    <p className="facebook-offer-surtitle">NO FEE REQUIRED!</p>
                                    <h1 className="facebook-offer-title">10 ROUNDS FOR FREE!</h1>

                                    <div className="facebook-offer-wrapper">

                                        <img src={board} className="facebook-offer-board" />
                                        <p className="facebook-offer-description">Amazing bonuses<br />up to 350%!</p>

                                    </div>

                                    {/* <a href="https://cherryspins.com/ca-en?register" className="btn btn-primary main-cta cta-large facebook-offer-button pulse-button">PLAY NOW!</a> */}

                                    <button className="btn btn-primary main-cta cta-large facebook-offer-button pulse-button" onClick={playNow}>PLAY NOW!</button>

                                </div>
                            </div>
                        </div>
                    </div>
                </section>



                <CherryspinsLoader />
            </div>
        </section>
    )
}